import SignupForm from "../../forms/signupForm"

const SignUp = () => {
    return (
        <div className="">
            <SignupForm />
        </div>
    )
}

export default SignUp;