import SigninForm from "../../forms/signinForm"

const SignIn = () => {
    return (
        <div>
            <SigninForm />
        </div>
    )
}

export default SignIn;